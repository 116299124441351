import { api } from '@briskly/shared';

export interface IReqParams {
  method: 'order' | 'settings' | 'task';
}
class ExchangeAPI {
  public getMenuList = async () => {
    return api.endpoints.current.get({ path: 'dashboard/exchange/get-list' });
  };

  public getMeta = async (method: IReqParams['method']) => {
    return api.endpoints.current.get({ path: `dashboard/exchange/${method}/get-fields-list` });
  };

  public getList = async (opts = {}, method: IReqParams) => {
    return api.endpoints.current.get({ path: `dashboard/exchange/${method}/get-list`, params: opts });
  };
}

export const exchangeApi = new ExchangeAPI();
