<template>
  <div class="notifications">
    <component
      v-for="item in items"
      :key="item.id"
      :is="computeMessageComponent(item.type)"
      :item="item"
      @close="onClose"
    />
    <div v-if="items.length > 2" class="closeButton">
      <Button @click="onCloseAll">Скрыть все уведомления</Button>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-icon.svg';
import { mapGetters, mapActions } from 'vuex';
import ChatMessage from '@/core/components/Notifications/ChatMessage/ChatMessage';
import RegularNotification from '@/core/components/Notifications/RegularNotification/RegularNotification';
import { Button } from '@briskly/ui-lib';

export default {
  components: {
    CloseIcon,
    ChatMessage,
    RegularNotification,
    Button,
  },

  computed: {
    ...mapGetters({ items: 'core$notifications/items' }),
  },

  methods: {
    ...mapActions({
      remove: 'core$notifications/remove',
      removeAll: 'core$notifications/removeAll',
    }),

    computeMessageComponent(type) {
      // ChatMessage - для оповещения о новых сообщениях в чатах
      // RegularNotification - системные сообщения
      return type === 'chat-message' ? 'ChatMessage' : 'RegularNotification';
    },

    onClose(id) {
      this.remove(id);
    },

    onCloseAll() {
      this.removeAll();
    },
  },
};
</script>

<style src="./Notifications.scss" lang="scss" scoped></style>
