<template>
  <component
    :to='item.link'
    :is='computeComponent'
    :class="[
      'menu-subheader',
      {
        active,
        forceActive: active && !!meta.forceActive,
        'with-menu': withMenu,
        'without-menu': !withMenu,
        'is-last': lastItem,
      },
    ]"
  >
    <div v-if='!isMenuCollapsed'>
      <component v-if='item.component' :is='item.component' />
      <span class='menu-subheader__title' v-else>
      {{ item.title }}
      <Badge v-if='item.meta.unassigned_count'>{{ item.meta.unassigned_count }}</Badge>
    </span>
    </div>
    <Avatar v-else>{{ item.title[0] }}</Avatar>
  </component>
</template>

<script>
import itemMixin from '../itemMixin';
import Badge from './Badge.vue';
import { Avatar } from '@briskly/ui-lib';

export default {
  mixins: [itemMixin],
  components: {
    Badge,
    Avatar,
  },
  props: {
    withMenu: {
      type: Boolean,
      required: true,
    },

    lastItem: {
      type: Boolean,
      default: false,
    },
    isMenuCollapsed: {
      type: Boolean,
    },

    meta: {
      type: Object,
      default: () => ({}),
    },
  },

  updated() {
    // console.log(this.active, this.meta);
  },
};
</script>

<style src='./SubHeader.scss' lang='scss' scoped />
