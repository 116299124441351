<template>
  <div class="no-content">
    <div class="no-content__icon"><SadSmile /></div>
    <div class="no-content__text"><slot></slot></div>
  </div>
</template>

<script>
import SadSmile from '@/assets/sad-smile.svg';

export default {
  components: {
    SadSmile,
  },
};
</script>

<style src="./NoContent.scss" lang="scss" scoped></style>
