import { busenessSectionsApi } from '@/apps/business-sections/api';

interface IParetsListResponse {
  label: string;
  field_name: string;
  elem: 'input' | 'checkbox' | 'multiselect' | 'radio' | 'date_picker_range';
  options?: any;
  fetchUrl?: string; //Ссылка на получение данных для мультиселекта
  is_sortable: boolean;
}

const defaultFormat = 'yyyy-MM-dd';

const buildOptionsForElem = (value: IParetsListResponse) => {
  if (!value.options) return false;

  switch (value.elem) {
    case 'multiselect':
      return Object.entries(value.options).map(([key, value]) => ({
        value: key,
        title: value,
        id: key,
        inputPlaceholder: value,
      }));

    default:
      break;
  }
};

const serialiseTableCols = (data: IParetsListResponse[]) => {
  const columns = [];
  const filterOptions = [];
  let requestFields = {};
  let filters = {};
  let sorting = {};

  data.forEach((value) => {
    const newColumn = {
      name: value.field_name,
      label: value.label,
      // removable: value.removable,
      removable: false,
      enableSorting: value.is_sortable || false,
    };
    const filterOption = {
      name: value.field_name,
      label: value.label,
      type: value.elem,
      ...(value.fetchUrl && { remoteMethod: value.fetchUrl }),
      ...(value.options && { options: buildOptionsForElem(value) }),
    };
    if (filterOption.type === 'date_picker_range') {
      //@ts-ignore
      filterOption.name = ['timestamp_inserting_start', 'timestamp_inserting_end'];
      //@ts-ignore
      filterOption.defaultFormat = defaultFormat;
      //@ts-ignore

      filterOption.type = 'date';
    }
    requestFields = { ...requestFields, [value.field_name]: `${value.field_name}` };

    filters =
      value.elem === 'date_picker_range'
        ? { ...filters, timestamp_inserting_start: [], timestamp_inserting_end: [] }
        : { ...filters, [value.field_name]: {} };

    sorting = { ...sorting, [value.field_name]: '' };
    columns.push(newColumn);
    filterOptions.push(filterOption);
  });
  return { columns, requestFields, filterOptions, sorting, filters };
};

const initialState = () => ({
  tableMeta: {
    pagination: {
      limit: 25,
      page: 1,
    },
    columns: [],
    requestFields: {},
    filters: {},
    sorting: {},
    filterOptions: [],
  },
  tableData: [],
});

const state = initialState();
type TState = typeof state;

const getters = {
  tableMeta: (state: TState) => state.tableMeta,
  tableData: (state: TState) => state.tableData,
};

const actions = {
  getMeta: async ({ commit }, route: string) => {
    try {
      const proccesedMeta: IParetsListResponse[] = await busenessSectionsApi.getMeta(route);
      const meta = serialiseTableCols(proccesedMeta);
      commit('_setTableMetaMeta', meta);
    } catch (error) {
      console.log(error);
    }
  },

  getList: async ({ commit }, { params, route }) => {
    try {
      commit('_setFlush');
      const response = await busenessSectionsApi.getList(params, route);
      const meta = { pagination: response.meta };
      commit('_setTableMetaMeta', meta);
      commit('_setTableData', response.items);
    } catch (error) {
      console.log(error);
    }
  },

  setMeta: async ({ commit }, { data, dataSet }) => {
    commit('_updateMeta', { dataSet, data });
  },
};

const mutations = {
  _setFlush: (state: TState) => {
    // const a  = initialState();
  },

  _setTableMetaMeta: (state: TState, meta) => {
    state.tableMeta = {
      ...state.tableMeta,
      columns: meta.columns ?? state.tableMeta.columns,
      requestFields: meta.requestFields ?? state.tableMeta.requestFields,
      pagination: meta.pagination ?? state.tableMeta.pagination,
      filters: meta.filters ?? state.tableMeta.filters,
      sorting: meta.sorting ?? state.tableMeta.sorting,
      filterOptions: meta.filterOptions ?? state.tableMeta.filterOptions,
    };
  },

  _setTableData: (state: TState, items) => {
    state.tableData = items;
  },

  _updateMeta: (state: TState, { data, dataSet }) => {
    state.tableMeta[dataSet] = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
