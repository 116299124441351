import menu from './store/menu';
import story from './store/story';
import store from '@/core/store';
// import router from '@/core/router';
// import routes from './routes';

export const connect = (opts = {}) => {
  store.registerModule('content$menu', menu);
  store.registerModule('content$stories', story);
};
