export default [
  {
    path: '/devices',
    component: () => import('@/apps/devices/views/Devices'),
    children: [
      {
        path: '/devices/monitoring',
        name: 'devices$monitoring',
        component: () => import('@/apps/devices/views/Monitoring/Monitoring'),
      },
      {
        path: '/devices/device_init',
        name: 'devices$init',
        component: () => import('@/apps/devices/views/DeviceInit/DeviceInit'),
      },

    ],
  },
];
