
const initialState = () => ({
  currentStories: null
});

const state = initialState();
// const actions = {
//
//   setChartList: ({ commit }, options) => {
//     console.log(options, 'action');
//     commit('_chartList', options);
//   },
// };

const mutations = {
  _storiesItem: (state, payload) => {
    state.currentStories = payload
  },
};
const getters = {
  storiesOptions: (state) => state.currentStories,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,

};
