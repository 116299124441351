<template>
  <div
    :class="['root-menu', 'sidebar-wrapper', forceCollapsed && 'd-force-collapsed', `d-${displayMode} `]"
    @click.self='toggleForceCollapse'
  >
    <div class='sidebar hide-scrollbar'>
      <div class='sidebar-logo'>
        <Icon name='menu' @click='toggleMenu' class='icon-menu' />
        <Logo class='icon-logo' @click="$router.push({ path: '/' })" />
      </div>
      <div class='sidebar-menu'>
        <Menu @openMenu='openMenu' ref='menu' :items='menu' :displayMode='displayMode' :level='0' />
      </div>

      <div class='sidebar-footer'>
        <slot name='profile'></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './Menu/Menu.vue';
import Logo from './logo.svg';
import { Icon } from '@briskly/ui-lib';

export default {
  emits: ['toggleMenu', 'openMenu'],
  components: { Menu, Icon, Logo },

  props: {
    logo: String,
    menu: {
      type: Array,
      default: () => [],
    },
    displayMode: String,
  },
  data() {
    return {
      forceCollapsed: false, // for mobile mode
    };
  },
  methods: {
    toggleMenu() {
      if (this.forceCollapsed) {
        this.$refs.menu.toggleSubMenu();
      }
      this.$emit('toggleMenu');
    },
    openMenu() {
      this.$emit('openMenu');
    },
    openForceMenu() {
      this.forceCollapsed = true;
    },
    toggleForceCollapse() {
      this.forceCollapsed = !this.forceCollapsed;
    },
    onLinkClick() {
      if (this.displayMode === 'mobile' || (this.displayMode === 'tablet' && this.forceCollapsed)) {
        this.forceCollapsed = !this.forceCollapsed;
      }
    },
  },
  mounted() {
    this._links = document.getElementsByClassName('sidebar-link');
    Array.prototype.forEach.call(this._links, (link) => {
      link.addEventListener('click', this.onLinkClick);
    });
  },
  beforeUnmount() {
    Array.prototype.forEach.call(this._links, (link) => {
      link.removeEventListener('click', this.onLinkClick);
    });
  },
};
</script>

<style src='./Sidebar.scss' lang='scss' scoped></style>
