export default {
  props: {
    item: {
      type: Object,
      required: true,
    },

    active: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computeComponent() {
      if (this.item.link) return 'router-link';
      return 'li';
    },
  },
};
