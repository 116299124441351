import menu from './store/menu';
import monitoring from './store/monitoring';
import store from '@/core/store';
// import * as channel from '@/apps/devices/io-channel';
import deviceinit from './store/deviceinit';

export const connect = (opts = {}) => {
  store.registerModule('devices$menu', menu);
  store.registerModule('devices$monitoring', monitoring);
  store.registerModule('devices$deviceinit', deviceinit);
  //@ts-ignore
  // channel.init({ socketOpts: { transports: ['websocket'], query: { allrooms: true } }, store });
};
