import { api } from '@briskly/shared';

class StatisticAPI {
  getMenuList = async () => {
    return api.endpoints.current.get({ path: 'dashboard/statistics/get-list' });
  };
  getStatisticPage = async () => {
    return api.endpoints.current.get({ path: 'dashboard/company/get-statistics' });
  };
  getStatisticGraphic = async () => {
    return api.endpoints.current.get({ path: 'dashboard/customer/get-statistics' });
  };
}
export const statisticApi = new StatisticAPI();
