export default [
  {
    path: '/statistics',
    component: () => import('@/apps/statistics/views/Statistics.vue'),
    children: [
      {
        path: 'micromarkets',
        component: () => import('@/apps/statistics/views/Statistics/Statistics.vue'),
      },
      {
        path: 'feedback',
        component: () => import('@/apps/statistics/views/Ratings.vue'),
      },
      {
        path: 'office_visit',
        component: () => import('@/apps/statistics/views/OfficeVisit.vue'),
      },
      {
        path: 'charts',
        component: () => import('@/apps/statistics/views/Charts/Charts'),
      },
    ],
  },
];
