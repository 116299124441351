import { bus } from '@briskly/shared';
import router from '@/core/router';
class SupportEvent {
  _store;

  init(store) {
    this._store = store;
    bus.on('chatMessage', this.onChatMessage);
    bus.on('chatStatusChanged', this.onChatStatusChanged);
  }

  onChatMessage = (message) => {
    this._store.dispatch('support$support/addMessageAction', message);
  };

  onChatStatusChanged = (message) => {
    this._store.dispatch('support$support/handleChatStatusChanged', message);
    this._store.dispatch('support$menu/updateUnassignedChatCount', router.currentRoute.value.params.funnel)
  };
}

export default new SupportEvent();
