import { bus, api } from '@briskly/shared';

class PushEvent {
  init() {
    bus.on('pushInit', this.onPushInit);
  }

  onPushInit = ({ userId, isSubscribed }) => {
    const url = `/dashboard/profile/push/${isSubscribed ? 'subscribe/' : 'unsubscribe/'}`;

    api.endpoints.current
      .post(url, { user_id: userId })
      .then((r) => console.log(r))
      .catch((e) => console.error(e));
  };
}

export default new PushEvent();
