<template>
  <div class="preloader">
    <PreloaderIcon />
  </div>
</template>

<script>
import PreloaderIcon from '@/core/components/PreloaderIcon.vue';

export default {
  components: {
    PreloaderIcon,
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
</style>
