import ChannelHandler from '@/core/channel-handler';
import { ChannelConnector } from '@briskly/shared';

let instance: ChannelHandler;

export function init(endpoint) {
  if (instance) return;

  instance = new ChannelHandler({ endpoint });

  new ChannelConnector(instance);
}

function wait() {
  return new Promise((resolve) => {
    const timer = setInterval(() => {
      if (instance.channel) {
        resolve(null);
        clearInterval(timer);
      }
    }, 100);
  });
}

export async function get() {
  if (instance.channel) return instance.channel;
  await wait();
  return instance.channel;
}
