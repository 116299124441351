<template>
  <Topbar class="system-msg-topbar">
    <template v-slot:left>
      <Breadcrumb :links="links" />
    </template>
  </Topbar>
</template>

<script>
import Breadcrumb from '@/core/components/Breadcrumb/Breadcrumb.vue';

import Topbar from '@/core/components/Topbar/Topbar';
export default {
  components: { Breadcrumb, Topbar },

  emits: ['onSearchSomeThing'],

  props: {
    links: { type: Array, default: [] },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    // onSearch({ target }) {
    //   this.$emit('onSearchSomeThing', target.value);
    // },
  },
};
</script>

<style src="./Toolbar.scss" lang="scss" scoped></style>
