import { createRouter, createWebHistory } from 'vue-router';
import support from '@/apps/support/routes';
import content from '@/apps/content/routes';
import partner from '@/apps/partner/routes';
import statistics from '@/apps/statistics/routes';
import devices from '@/apps/devices/routes';
import bsections from '@/apps/business-sections/routes';
import exchange from '@/apps/exchange/routes';

import Index from '@/core/views/Index.vue';
import Main from '@/core/views/Main.vue';
import Preloader from '@/core/views/Preloader.vue';
import ExternalRedirect from '@/core/views/ExternalRedirect.vue';
import Home from '@/core/views/Home/Home.vue';
import NotFound from '@/core/views/NotFound.vue';
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/support/company/funnel/-1',
    },

    {
      path: '/',
      alias: '/home',
      component: Index,
      children: [
        {
          path: '',
          component: Main,
          children: [
            {
              path: '',
              name: 'main',
              component: Home,
            },
            ...support,
            ...bsections,
            ...content,
            ...partner,
            ...statistics,
            ...devices,
            ...exchange,
          ],
        },

        {
          path: 'external_redirect',
          name: 'external-redirect',
          component: ExternalRedirect,
        },
        { path: '/:pathMatch(.*)', component: NotFound },
      ],
    },

    {
      path: '/loading',
      name: 'loading',
      component: Preloader,
    },
  ],
});

export default router;
