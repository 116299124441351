<template>
  <component :is="displayedComponent" />
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import Preloader from '@/core/views/Preloader.vue';
import { Init } from '@/core/data/init';

export default {
  components: {
    Preloader,
  },

  computed: {
    ...mapGetters({
      loading: 'core$main/loading',
      isReady: 'core$main/isReady',
    }),

    displayedComponent() {
      return this.loading ? 'preloader' : 'router-view';
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions({
      init: 'core$main/init',
    }),
  },
};
</script>
