export default [
  {
    path: '/content',
    component: () => import('@/apps/content/views/Content'),
    children: [
      {
        path: 'discounts',
        name: 'content$discounts',
        component: () => import('@/apps/content/views/Discounts/Discounts'),
      },

      {
        path: 'logos',
        name: 'content$logos',
        component: () => import('@/apps/content/views/Logos/Logos'),
      },
      {
        path: 'delivery',
        name: 'content$delivery',
        component: () => import('@/apps/content/views/Delivery/Delivery'),
        children: [
          {
            path: 'new',
            name: 'delivery-new',
            component: () => import('@/apps/content/views/Delivery/CreateDelivery/CreateDelivery'),
          },
          {
            path: 'edit/:deliveryId',
            name: 'delivery-edit',
            props: true,
            component: () => import('@/apps/content/views/Delivery/CreateDelivery/CreateDelivery'),
          },
        ],
      },
      {
        path: 'stories',
        name: 'content$stories',
        component: () => import('@/apps/content/views/Stories/Stories'),

        children: [
          {
            path: 'new',
            name: 'new-stories',
            component: () => import('@/apps/content/views/Stories/NewStories/NewStories'),
          },
          {
            path: 'edit/:storyId',
            name: 'edit-stories',
            component: () => import('@/apps/content/views/Stories/NewStories/NewStories'),
          },
        ],
      },
      {
        path: 'system-messages',
        name: 'content$system-messages',
        component: () => import('@/apps/content/views/SystemMessages/SystemMessages'),
      },
    ],
  },

];
