export default [
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('@/apps/exchange/views/Exchange.vue'),
    children: [
      {
        path: '/exchange/order',
        name: 'exchange$order',
        component: () => import('@/apps/exchange/views/Tables/Tables'),
      },
      {
        path: '/exchange/settings',
        name: 'exchange$settings',
        component: () => import('@/apps/exchange/views/Tables/Tables'),
      },
      {
        path: '/exchange/task',
        name: 'exchange$task',
        component: () => import('@/apps/exchange/views/Tables/Tables'),
      },
    ],
  },
];
