<template>
  <div class="breadcrumb">
    <template v-for="(link, i) in links" :key="i">
      <span v-if="!link.url" :class="['breadcrumb__title']">{{ link.title }}</span>
      <router-link v-else tag="a" :to="'/' + link.url" :class="['breadcrumb__title']">{{ link.title }}</router-link>
      <span v-if="i !== links.length - 1">/</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped src="./Breadcrumb.scss"></style>
