import { api } from '@briskly/shared';
import axios from 'axios';
class PartnerAPI {
  getMainPageAnalytics = async (opts = {}) => {
    const { q } = opts;
    return api.endpoints.current.post({ path: 'dashboard/partner/get-list', params: { q } });
  };

  loginAsPartner = async (opts = {}) => {
    const { company_id } = opts;
    return api.endpoints.current.post({ path: 'dashboard/partner/login-as-partner', params: { company_id } });
  };

  async getLinkOnCompany(opts = {}, repeat = true) {
    const { company_id } = opts;
    const { refresh_token, access_token } = api.tokens.getTokens();

    try {
      const data = await axios.post(
        'https://backoffice.briskly.online/company-authorization/login-as-partner/',
        { company_id },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
      );

      return data;
    } catch (error) {
      if (!error.response || error.response.status !== 401) throw error;

      if (repeat) return this.getLinkOnCompany(opts, false);
    }
  }
}
export const partnerApi = new PartnerAPI();
