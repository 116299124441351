import { api } from '@briskly/shared';

interface IModule {
  state?: string[];
  stores?: string[];
  device: 'misc' | 'terminal' | 'module';
}

class DeviceApi {
  // api/backoffice/v1/dashboard/device/get-list
  // api/backoffice/v1/dashboard/device/get-available-types

  bindDevice = async (opts: any) => {
    console.log(opts);

    const { params } = opts;
    console.log(api.endpoints);

    return api.endpoints.internal.internal.post({
      path: '/device/init',
      params,
    });
  };

  getMonitoringModule = async (opts: IModule = { device: 'misc' }) => {
    const { device, state, stores } = opts;

    const params = {
      state,
      store_id: stores,
      device,
    };

    return api.endpoints.current.post({
      path: 'dashboard/device/monitoring/get-list',
      params,
    });
  };

  public getMenuList = async () => {
    return api.endpoints.current.get({ path: 'dashboard/device/get-list' });
  };
  public getDeviceTypes = async () => {
    return api.endpoints.current.get({ path: 'dashboard/device/get-available-types' });
  };
}

export const deviceApi = new DeviceApi();
