<template>
  <div class="notification-item">
    <div class="item__header">
      <span class="item__header-title">{{ item.value.title }}</span>

      <CloseIcon @click="onClose" class="item__header-close" />
    </div>

    <div class="item__body">{{ item.value.message }}</div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-icon.svg';
import Button from '@/core/components/Controls/Button/Button';

export default {
  emits: ['close'],

  components: {
    CloseIcon,
    Button,
  },

  props: {
    item: {
      type: Object,
    },
  },

  methods: {
    onClose() {
      this.$emit('close', this.item.id);
    },
    autoClose() {
      setTimeout(this.onClose, 7000);
    },
  },

  mounted() {
    this.autoClose();
  },
};
</script>

<style src="./RegularNotification.scss" lang="scss" scoped></style>
