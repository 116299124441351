<template>
  <div class="bsections">
    <Toolbar :links="links" />
    <section v-if="maybeRouterView" class="section">
      <router-link v-for="item in mainPageItems" :key="item.id" :to="item.link" class="section-card">
        <div class="card-image"></div>
        <div class="card-label">
          {{ item.label }}
        </div>
      </router-link>
    </section>
    <router-view v-else :key="$route.fullPath" />
  </div>
</template>

<script>
import Toolbar from '@/apps/business-sections/components/Toolbar/Toolbar';
import { mapGetters } from 'vuex';
export default {
  components: {
    Toolbar,
  },

  computed: {
    ...mapGetters({
      mainPageItems: 'bsections$main/mainPageItems',
    }),

    maybeRouterView() {
      return this.$route.path === '/bsections';
    },

    links() {
      const [_, __, curRoute] = this.$route.fullPath.split('/');
      const crumb = { title: this.mainPageItems.find((r) => r.route === curRoute).label };
      return [{ title: 'Данные' }, crumb];
    },
  },
  created() {
    // this.mainPageItems.forEach((item) => {
    //   const route = {
    //     path: item.link,
    //     name: item.link,
    //     component: () => Table,
    //     meta: {
    //       breadCrumb: [
    //         ...this.$route.meta.breadCrumb,
    //         {
    //           label: item.name,
    //           route: { name: item.link },
    //         },
    //       ],
    //     },
    //   };
    //   this.$router.addRoute('bsections', route);
    // });
  },
};
</script>

<style lang="scss" scoped>
.bsections {
  width: 100%;
  background-color: #f7fafc;
  padding: 20px;
  padding-top: 0;
  height: 100%;
}
.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.section-card {
  margin: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 64px;

  width: 208px;
  height: 209px;
  background: #f7fafc;

  border: 1px solid #dcdee6;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  .card-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    text-decoration: none;
  }
}
</style>
