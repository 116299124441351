<template>
  <div class="topbar">
    <div class="topbar-left">
      <slot name="left"></slot>
    </div>

    <div class="topbar-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style src="./Topbar.scss" lang="scss" scoped></style>
