<template>
  <div class="chat-message-item">
    <div class="item__header">
      <span class="item__header-title">Сообщение ({{ formatCustomerName }}) </span>

      <CloseIcon @click="onClose" class="item__header-close" />
    </div>

    <div class="item__body">{{ text }}</div>

    <div class="item__footer">
      <Button @click="onClick">Перейти</Button>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-icon.svg';
import Button from '@/core/components/Controls/Button/Button';
import { mapActions, mapGetters } from 'vuex';

export default {
  emits: ['close'],

  components: {
    CloseIcon,
    Button,
  },

  props: {
    item: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters({ items: 'core$notifications/items' }),

    formatCustomerName() {
      const opts = this.item.value.chat;

      const { id, customer, employee, name } = opts;
      const { first_name, last_name } = customer || employee || {};
      const { id: entity_id } = customer || employee || { id };

      if (first_name && last_name) return `${first_name} ${last_name} ${entity_id}`;
      if (customer) return `Покупатель ${entity_id}`;
      if (employee) return `Сотрудник ${entity_id}`;
      if (name) return name;

      return `Чат ${id}`;
    },

    text() {
      const message = this.item.value;
      if (message.files.length) return '** Прикреплённые файлы **';
      return message.text;
    },
  },

  methods: {
    ...mapActions({
      flush: 'support$support/flush',
    }),

    onClose() {
      this.$emit('close', this.item.id);
    },

    async onClick() {
      const { id: chatId } = this.item.value.chat;

      if (this.items) {
        const targets = this.items.filter((i) => i.value.chat && i.value.chat.id === chatId);
        targets.forEach(({ id }) => this.$emit('close', id));
      }

      await this.flush();
      await this.$router.replace({ name: 'external-redirect', query: { chat: chatId } });
    },
  },
};
</script>

<style src="./ChatMessage.scss" lang="scss" scoped></style>
