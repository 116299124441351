import support from './store/support';
import menu from './store/menu';
import store from '@/core/store';
// import router from '@/core/router';
// import routes from './routes';
import events from './events';

export const connect = (opts = {}) => {
  store.registerModule('support$support', support);
  store.registerModule('support$menu', menu);
  events.init(store);
};
