<template>
  <div class="preloader">
    <NoContent>Страница не найдена</NoContent>
  </div>
</template>

<script>
import NoContent from '@/core/components/NoContent/NoContent.vue';
export default {
  components: {
    NoContent,
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
</style>
