<template>
  <router-link
    :to="{ ...item.route }"
    :class="['menu-button sidebar-link', routeIsExactActive && 'routeIsExactActive']"
  >
    <span>{{ item.title }}</span>
  </router-link>
</template>

<script>

export default {
  props: {
    item: Object,
  },
};
</script>

<style src="./Button.scss" lang="scss" scoped></style>
