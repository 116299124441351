import DevicesIcon from '@/assets/img/business-sections-icon.svg';
import { markRaw } from 'vue';

import { busenessSectionsApi } from '../api';

const initialState = () => ({
  menu: [
    {
      id: 'bsections',
      title: 'Данные',
      type: 'header',
      icon: markRaw(DevicesIcon),
      meta: {},
      // link: '/bsections',
      nodes: [],
    },
  ],
});

const state = initialState();
type TState = typeof state;

const getters = {
  menu: (state: TState) => state.menu,
};

const actions = {
  getChildSubheader: async ({ commit, dispatch }) => {
    try {
      const nodes = await busenessSectionsApi.getMenuList();
      await dispatch('bsections$main/setMainPageItems', nodes, { root: true });
      commit('_setChildMenus', nodes);
    } catch (error) {
      console.log(error);
      commit('_cleanThisMenu');
    }
  },
};

const mutations = {
  _setChildMenus: (state: TState, nodes) => {
    const proccessedNodes = [];
    Object.entries(nodes.sections).forEach(([key, value]) => {
      const node = {
        id: `bsections/${key}`,
        //@ts-ignore
        title: value.label,
        type: 'subheader',
        link: `/bsections/${key}`,
        meta: {},
      };
      proccessedNodes.push(node);
    });

    const updatedMenu = {
      ...state.menu[0],
      nodes: proccessedNodes,
    };
    state.menu[0] = updatedMenu;
  },

  _cleanThisMenu: (state) => {
    state.menu = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
