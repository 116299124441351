import BSections from '@/apps/business-sections/views/BusinessSections';

export default [
  {
    path: 'bsections',
    name: 'bsections',
    component: BSections,
    children: [
      {
        path: '/bsections/store',
        name: 'bsections$store',
        component: () => import('@/apps/business-sections/views/Tables/Tables'),
      },
      {
        path: '/bsections/company',
        name: 'bsections$company',
        component: () => import('@/apps/business-sections/views/Tables/Tables'),
      },
      {
        path: '/bsections/customer',
        name: 'bsections$customer',
        component: () => import('@/apps/business-sections/views/Tables/Tables'),
      },
      {
        path: '/bsections/item',
        name: 'bsections$item',
        component: () => import('@/apps/business-sections/views/Tables/Tables'),
      },
      {
        path: '/bsections/order',
        name: 'bsections$order',
        component: () => import('@/apps/business-sections/views/Tables/Tables'),
      },
      {
        path: '/bsections/employee',
        name: 'bsections$employee',
        component: () => import('@/apps/business-sections/views/Tables/Tables'),
      },
      {
        path: '/bsections/delivery',
        name: 'bsections$delivery ',
        component: () => import('@/apps/business-sections/views/Tables/Tables'),
      },
      {
        path: '/bsections/category',
        name: 'bsections$category ',
        component: () => import('@/apps/business-sections/views/Tables/Tables'),
      },
      {
        path: '/bsections/catalog',
        name: 'bsections$catalog ',
        component: () => import('@/apps/business-sections/views/Tables/Tables'),
      },
    ],
  },
  {
    path: '/bsections/order/:order_id',
    name: 'bsections$order$order_id',
    component: () => import('@/apps/business-sections/views/Order/Order'),
  },
];
