import DevicesIcon from '@/assets/img/devices-icon.svg';

import { markRaw } from 'vue';
import { deviceApi } from '@/apps/devices/api';

const initialState = () => ({
  menu: [
    {
      id: 'devices',
      title: 'Оборудование',
      type: 'header',
      icon: markRaw(DevicesIcon),
      meta: {},
      nodes: [
        {
          id: 'devices/monitoring',
          title: 'Мониторинг',
          type: 'subheader',
          meta: {
            unassigned_count: '',
          },
          link: '/devices/monitoring',
        },
        // {
        //   id: 'devices/terminals',
        //   title: 'Терминалы',
        //   type: 'subheader',
        //   meta: {
        //     unassigned_count: '',
        //   },
        //   link: '/devices/terminals',
        // },
      ],
    },
  ],
});

const state = initialState();
type TState = typeof state;

const getters = {
  menu: (state) => state.menu,
};

const actions = {
  getChildSubheader: async ({ commit, dispatch }) => {
    try {
      const nodes = await deviceApi.getMenuList();
      // await dispatch('bsections$main/setMainPageItems', nodes, { root: true });
      commit('_setChildMenus', nodes);
    } catch (error) {
      console.log(error);
      commit('_cleanThisMenu');
    }
  },
};

const mutations = {
  _setChildMenus: (state: TState, nodes) => {
    const processedNodes = [];
    Object.entries(nodes.sections).forEach(([key, value]) => {
      const node = {
        id: `devices/${key}`,
        //@ts-ignore
        title: value.label,
        type: 'subheader',
        link: `/devices/${key}`,
        meta: {},
      };
      processedNodes.push(node);
    });

    const updatedMenu = {
      ...state.menu[0],
      nodes: processedNodes,
    };
    state.menu[0] = updatedMenu;
  },
  _cleanThisMenu: (state) => {
    state.menu = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
