<template>
  
  <button :disabled="disabled" :class="['button', { primary, secondary, upload }]">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    primary: Boolean,
    secondary: Boolean,
    upload: Boolean,
    disabled: Boolean,
  },
};
</script>

<style src="./Button.scss" lang="scss" scoped></style>