// import supportMenu from '@/apps/support/data/menu';
// import chatsMenu from '@/apps/chats/data/menu';

const initialState = () => ({
  menu: [],
});

const state = initialState();

const getters = {
  menu: (state, _getters, _rootState, rootGetters) => [
    ...rootGetters['support$menu/menu'],
    ...rootGetters['bsections$menu/menu'],
    ...rootGetters['content$menu/menu'],
    ...rootGetters['partner$menu/menu'],
    ...rootGetters['devices$menu/menu'],
    ...rootGetters['exchange$menu/menu'],
    ...rootGetters['statistics$menu/menu'],
    ...state.menu,
  ],
};

export default {
  namespaced: true,
  state,
  getters,
};
