<template>
  <div class="home">
    <NoContent>Здесь пусто</NoContent>
  </div>
</template>

<script>
import NoContent from '@/core/components/NoContent/NoContent';

export default {
  components: {
    NoContent,
  },
};
</script>

<style src="./Home.scss" lang="scss" scoped></style>
