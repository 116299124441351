import '@briskly/shared/dist/lib.css';
import 'swiper/swiper-bundle.css';
import '@/core/styles/main.scss';
import '@/core/styles.scss';
import 'regenerator-runtime';
import '@/core/scripts/override';
import '@briskly/ui-lib/dist/lib.css';

import { init, modules, api } from '@briskly/shared';

import app from '@/core/application';
import router from '@/core/router';
import store from '@/core/store';
import i18n from '@/core/i18n';
import mainEvent from '@/core/events/main';

const VueScrollTo = require('vue-scrollto');
import * as support from '@/apps/support';
import * as content from '@/apps/content';
import * as partner from '@/apps/partner';
import * as statistics from '@/apps/statistics';
import * as devices from '@/apps/devices';
import * as channel from '@/core/channel';
import * as bsections from '@/apps/business-sections';
import * as exchange from '@/apps/exchange';

import pushEvents from '@/core/events/push';
import { checkVersion } from '@/core/scripts/version-checker';

const { auth, profile } = modules;

(async () => {
  await checkVersion();

  const countries = [{ name: 'ru', code: 7, mask: '+7 (###) ###-##-##' }];

  localStorage.setItem('TLocale', 'ru');
  api.endpoints.setCurrent(api.endpoints.internal.backoffice);

  mainEvent.init(store);

  init({
    store,
    router,
    app,
    i18n,
    PROJECT_NAME: PROJECT_NAME,
    API_URL: process.env.API_URL,
    WEBSOCKET_URL: process.env.WEBSOCKET_URL,
    IOSOCKET_URL: process.env.IOSOCKET_URL,
  });

  channel.init(api.endpoints.current);

  pushEvents.init();

  await auth.connect({ withPassword: true, countries, selectedCountry: 'ru' });
  await profile.connect({ store, router });
  await support.connect();
  await content.connect();
  await partner.connect();
  await statistics.connect();
  await devices.connect();
  await bsections.connect();
  await exchange.connect();

  app.use(i18n);
  app.use(router);
  app.use(store);
  app.use(VueScrollTo, {
    container: '#groupedMessages',
    duration: 500,
    easing: 'ease',
    offset: 0,
  });

  app.mount('#app');
})();
