import { deviceApi } from '@/apps/devices/api';

const initialState = () => ({
  devicePool: [],
});

const state = initialState();

const getters = {
  devicePool: (state) => state.devicePool,
};

const actions = {
  addDeviceToPool: ({ commit }, device) => {
    const processedDevice = {
      ...device,
      query: device.query.reduce((acc, cur) => ({ ...acc, [cur]: '' }), {}),
    };
    commit('_addDeviceToPool', processedDevice);
  },

  initializeDevice: async ({ commit }, params) => {
    console.log(params);

    try {
      await deviceApi.bindDevice({ params });
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  _addDeviceToPool: (state, device) => (state.devicePool = [...state.devicePool, device]),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
