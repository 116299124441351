import { createStore } from 'vuex';
import core$main from '@/core/stores/main';
import core$orders from '@/core/stores/orders';
import core$browser from '@/core/stores/browser';
import core$menu from '@/core/stores/menu';
import core$notifications from '@/core/stores/notifications';
import core$employeePreferences from '@/core/stores/employeePreferences';

const store = createStore({
  modules: {
    core$main,
    core$orders,
    core$menu,
    //@ts-ignore
    core$browser,
    core$notifications,
    core$employeePreferences,
  },
});

export default store;
