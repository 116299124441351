// import { companyAPI } from '@/core/api/company';

const state = {
  employeePreferences: {
    // catalogs_hidden_fields: [],
    // categories_hidden_fields: [],
    // discounts_hidden_fields: [],
    // documents_hidden_fields: [],
    // employees_hidden_fields: [],
    // items_hidden_fields: [],
    // parties_hidden_fields: [],
    // push_distributions_hidden_fields: [],
    // stores_hidden_fields: [],
    // orders_hidden_fields: [],
    // report_main_hidden_fields: [],
    // report_promo_hidden_fields: [],
    // report_delivery_hidden_fields: [],
    store_hidden_fields: [],
  },
};

const getters = {
  employeePreferences: (state) => state.employeePreferences,
  // catalogsHiddenFields: (state) => state.employeePreferences.catalogs_hidden_fields,
  // categoriesHiddenFields: (state) => state.employeePreferences.categories_hidden_fields,
  // discountsHiddenFields: (state) => state.employeePreferences.discounts_hidden_fields,
  // documentsHiddenFields: (state) => state.employeePreferences.documents_hidden_fields,
  // employeesHiddenFields: (state) => state.employeePreferences.employees_hidden_fields,
  storeHiddenFields: (state) => state.employeePreferences.store_hidden_fields,
  // partiesHiddenFields: (state) => state.employeePreferences.parties_hidden_fields,
  // pushDistributionsHiddenFields: (state) => state.employeePreferences.push_distributions_hidden_fields,
  // storesHiddenFields: (state) => state.employeePreferences.stores_hidden_fields,
  // ordersHiddenFields: (state) => state.employeePreferences.orders_hidden_fields,
  // reportMainHiddenFields: (state) => state.employeePreferences.report_main_hidden_fields,
  // reportPromoHiddenFields: (state) => state.employeePreferences.report_promo_hidden_fields,
  // reportDeliveryHiddenFields: (state) => state.employeePreferences.report_delivery_hidden_fields,
};

const actions = {
  getEmployeePreferences: async ({ commit }) => {
    try {
      const { preferences } = [];
      // await companyAPI.getEmployeePreferences();
      commit('_setPreferences', preferences);
    } catch {}
  },

  setEmployeePreferences: async ({ state }, { key, preferences }) => {
    if (!key) return console.error('The `key` parameter is required!');

    // await companyAPI.setEmployeePreferences({ key, preferences });
  },

  resetKey: async ({ state }, key) => {
    if (!key) return console.error('The `key` parameter is required!');

    // await companyAPI.setEmployeePreferences({ key, preferences: [] });
  },
};

const mutations = {
  _setPreferences: (state, preferences) => (state.employeePreferences = preferences),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
