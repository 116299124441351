<template>
  <component
    :to="item.link"
    :is="computeComponent"
    :class="['menu-header', { active, 'with-menu': withMenu, 'without-menu': !withMenu }]"
  >
    <div class="menu-header__title">
      <component v-if="item.icon" class="menu-header__icon" :is="item.icon" />
      <span class="menu-header__title-main">{{ item.title }}</span>
    </div>
  </component>
</template>

<script>
import itemMixin from '../itemMixin';

export default {
  mixins: [itemMixin],

  props: {
    withMenu: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style src="./Header.scss" lang="scss" scoped></style>
