import PartnerIcon from '@/assets/img/partner-icon.svg';
import { markRaw } from 'vue';

const initialState = () => ({
  menu: [
    {
      id: 'partner',
      title: 'Воронки',
      type: 'header',
      icon: markRaw(PartnerIcon),
      link: '/partner',
    },
  ],
});

const state = initialState();

const getters = {
  menu: (state) => state.menu,
};

export default {
  namespaced: true,
  state,
  getters,
};
