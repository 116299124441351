import { api } from '@briskly/shared';

type possibleMode = 0 | 1;
interface IRefundCreate {
  parent_order_id?: number;
  items?: any[];
  mode?: possibleMode;
}

interface IRefundApprove {
  order_id?: number;
  pay_u_order_id?: number;
  mode?: possibleMode;
}

class BSectionsApi {
  public getMenuList = async () => {
    return api.endpoints.current.get({ path: 'dashboard/data/get-list' });
  };

  public getMeta = async (route: string) => {
    return api.endpoints.current.get({ path: `dashboard/data/${route}/get-fields-list` });
  };

  public getList = async (opts = {}, route: string) => {
    return api.endpoints.current.get({
      path: `dashboard/data/${route}/get-list`,
      params: opts,
    });
  };

  public getItemById = async ({ order_id = 0, route = '' }: { order_id?: number; route: string }) => {
    const params = {
      ...(order_id && { id: order_id }),
    };
    return api.endpoints.current.get({ path: `dashboard/data/${route}/get-by-id`, params });
  };

  public refundCreate = async (opts: IRefundCreate = {}) => {
    const { parent_order_id, items, mode } = opts;
    return api.endpoints.current.post({
      path: `dashboard/data/order/refund/create`,
      params: { parent_order_id, items, mode },
    });
  };

  public refundApprove = async (opts: IRefundApprove = {}) => {
    const { order_id, pay_u_order_id, mode } = opts;
    return api.endpoints.current.post({
      path: `dashboard/data/order/refund/refund`,
      params: { order_id, pay_u_order_id, mode },
    });
  };
}

export const busenessSectionsApi = new BSectionsApi();
