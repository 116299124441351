
import Preloader from '@/core/views/Preloader.vue';
import { mainAPI } from '@/core/api/main';

interface THandleQueryParams {
  chat?: string;
  client?: string;
  type?: 'customer' | 'employee' | 'company';
}

interface THandleFunnelChatParams {
  chat_id: number;
  type: 'company';
  funnel: number;
  status: number;
}

interface THandleGeneralChatParams {
  chat_id: number;
  status: number;
  type: 'customer' | 'employee';
}

export default {
  components: {
    Preloader,
  },

  methods: {
    init() {
      this.handleQuery(this.$route.query);
    },

    async handleQuery(opts: THandleQueryParams = {}) {
      const { chat: chat_id, client: client_id, type } = opts;

      if (chat_id) {
        try {
          const { funnel, type, status } = await mainAPI.getChatMeta({ id: Number(chat_id) });

          if (typeof funnel === 'number') return this.handleFunnelChat({ funnel, type, chat_id, status });
          return this.handleGeneralChat({ chat_id, type, status });
        } catch (error) {
          console.error(error);
          return this.routeToMain({ error });
        }
      } else if (client_id && type) {
        try {
          const { id } = await mainAPI.findChatByClient({ id: Number(client_id), type });

          if (id) return this.handleQuery({ chat: id });
          else return this.routeToMain();
        } catch (error) {
          console.error(error);
          return this.routeToMain({ error });
        }
      } else {
        return this.routeToMain();
      }
    },

    async handleFunnelChat(opts: THandleFunnelChatParams) {
      const { type, funnel, chat_id, status } = opts;

      if (type === 'company') {
        this.$router.replace({ name: 'support$company-chat', params: { funnel, chat: chat_id, status } });
      } else throw new Error(`[handleFunnelChat] unknown type with funnel: funnel: ${funnel} type: ${type}`);
    },

    async handleGeneralChat(opts: THandleGeneralChatParams) {
      const { chat_id, type, status } = opts;

      switch (type) {
        case 'employee':
          return this.$router.replace({ name: 'support$employee-chat', params: { chat: chat_id, status } });

        case 'customer':
          return this.$router.replace({ name: 'support$customer-chat', params: { chat: chat_id, status } });
      }
    },

    async routeToMain(opts = {}) {
      this.$router.replace({ name: 'main' });
    },
  },

  mounted() {
    this.init();
  },
};
